<template>
  <div class="container-fluid" :class="{ loading: loading }">
    <div v-if="globalOrganization?.id" class="page-wrapper">
      <div class="map-management">
        <div class="map-management-content">
          <div
            ref="mapContainer"
            class="image-container"
            :class="{
              dragging: zoomOptions.isDragging,
              loading: loading,
            }"
            @mousedown.prevent="onMouseDownImage"
            @mousemove.prevent="onMouseMoveImage"
            @mouseup.prevent="onMouseUpImage"
            @mouseleave.prevent="onMouseLeaveImage"
            @wheel.prevent="zoom"
            @contextmenu.prevent="() => {}"
          >
            <img
              id="map-image"
              ref="imageContainer"
              :src="imageSrc"
              :style="imageStyle"
            />

            <div
              ref="mapModalContainer"
              v-if="showMapModal"
              class="map-form"
              :style="getMapModalStyle()"
            >
              <div class="map-form-header">
                <p>
                  {{ $t("BOOKINGS.SELECTED_PERIOD") }}
                </p>
                <ul class="dates" v-if="searchCriteria.dateRange.length == 2">
                  <li>
                    {{
                      $formatDate(searchCriteria.dateRange[0], "DD MMM YYYY")
                    }}
                  </li>
                  <li class="sep">-</li>
                  <li>
                    {{
                      $formatDate(searchCriteria.dateRange[1], "DD MMM YYYY")
                    }}
                  </li>
                </ul>
                <button @click="closeMapModal" class="close">
                  <i class="fal fa-times"></i>
                </button>
              </div>
              <div class="map-form-content">
                <div class="location">
                  <span>{{ $t("COMMON.SPOT") }}</span>
                  <div class="location-selected">
                    <div
                      class="located"
                      v-if="spotsMap[currentSpot.spotId]?.id"
                    >
                      <div class="located-item">
                        <div class="located-item-image">
                          <img
                            :src="
                              spotsMap[currentSpot.spotId].category.icon
                                ? spotsMap[currentSpot.spotId].category.icon
                                : '/img/default-category-icon.svg'
                            "
                            style="max-height: 35px; display: inline"
                            alt="icon"
                          />
                        </div>
                        <div class="located-item-text">
                          <span>{{ spotsMap[currentSpot.spotId].code }}</span>
                          <span>
                            {{ spotsMap[currentSpot.spotId].spotType.name }}
                          </span>
                        </div>
                      </div>
                      <div class="located-item">
                        <button
                          class="add-booking"
                          @click="onAddBooking(currentSpot.spotId)"
                        >
                          {{ $t("BOOKINGS.NEW_BOOKING") }}
                        </button>
                      </div>
                    </div>
                    <p v-else>{{ $t("COMMON.NONE") }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="(hotspot, index) in hotspots.filter(
                (item) => item.x != 0 && item.y != 0
              )"
              :key="index"
              :style="getHotspotStyle(hotspot)"
              :title="hotspot.code"
              :class="{}"
              @mousedown.prevent="(event) => onMouseDownHotspot(event, hotspot)"
              class="hotspot"
            >
              <span class="hotspot-marker"></span>
              <span
                class="hotspot-code"
                @mousedown.prevent="
                  (event) => onMouseDownHotspot(event, hotspot)
                "
              >
                {{ hotspot.code }}
              </span>
            </div>

            <div class="zoom-controls" ref="zoomControlsContainer">
              <button @click.stop="zoomIn">
                <i class="far fa-plus"></i>
              </button>
              <button @click.stop="zoomOut">
                <i class="far fa-minus"></i>
              </button>
            </div>
          </div>

          <div v-if="!organizationConfig.SPOTS_MAP_IMAGE_URL" class="no-image">
            <img src="/img/map-placeholder.svg" alt="map" />
            <p>{{ $t("COMMON.NO_IMAGE_ADDED") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="page-wrapper orga-select">
      <img src="/img/kw-fire.svg" alt="icon" />
      <p>{{ $t("COMMON.PLEASE_SELECT_ORGANIZATION") }}</p>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import { Button, Option, Select } from "element-ui";
import { mapGetters } from "vuex";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  OPTIONS_SPOTS_MAP_IMAGE_URL,
  OPTIONS_SPOTS_MAP_IMAGE_DATA,
} from "@/constants/options";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: {
    spots: {
      type: Array,
      required: true,
    },
    searchCriteria: {
      type: Object,
      required: true,
    },
  },

  mixins: [requestErrorMixin],

  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),

    imageStyle() {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      return {
        transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
        width: `${this.imageWidth}px`,
        height: `${this.imageHeight}px`,
      };
    },
  },

  watch: {
    organizationConfig: async function () {
      await this.loadMapData();
      await this.loadImage();
    },
    spots: async function () {
      await this.refreshSpotsMap();
    },
  },

  data() {
    return {
      imageSrc: "",
      imageWidth: 0,
      imageHeight: 0,
      zoomOptions: {
        scale: 1,
        offsetX: 0,
        offsetY: 0,
        startX: 0,
        startY: 0,
        isDragging: false,
        hasDragged: false,
      },
      currentSpot: {
        x: 0,
        y: 0,
        isDragging: false,
        hasDragged: false,
      },
      mapData: {
        pointSize: 15,
        defaultZoom: 1,
      },
      hotspots: [],
      spotsMap: {},
      loading: false,
      showMapModal: false,
      dragStartsAt: null,
    };
  },

  async mounted() {
    await this.refreshSpotsMap();
    await this.loadMapData();
    await this.loadImage();
  },

  methods: {
    fetchImage(url) {
      this.loading = true;
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          const calculateDimensions = (width, height, maxDimension) => {
            let adjustedHeight = width;
            let adjustedWidth = height;
            if (width > height) {
              adjustedWidth = Math.ceil(width * (maxDimension / width));
              adjustedHeight = Math.ceil((adjustedWidth * height) / width);
            } else {
              adjustedHeight = Math.ceil(height * (maxDimension / height));
              adjustedWidth = Math.ceil((adjustedHeight * width) / height);
            }
            return { adjustedWidth, adjustedHeight };
          };

          const container = this.$refs.mapContainer;
          const containerWidth = container.offsetWidth;
          const containerHeight = container.offsetHeight;

          const { adjustedWidth, adjustedHeight } = calculateDimensions(
            img.width,
            img.height,
            1000
          );
          this.imageWidth = adjustedWidth;
          this.imageHeight = adjustedHeight;

          const defaultOffsetX =
            (containerWidth - adjustedWidth * this.zoomOptions.scale) / 2;
          const defaultOffsetY =
            (containerHeight - adjustedHeight * this.zoomOptions.scale) / 2;
          let scale =
            Math.min(containerWidth, containerHeight) /
            Math.min(adjustedWidth, adjustedHeight);

          const zoomOptions = cloneDeep(this.zoomOptions);
          zoomOptions.offsetX = defaultOffsetX;
          zoomOptions.offsetY = defaultOffsetY;
          zoomOptions.scale = scale;
          this.zoomOptions = zoomOptions;

          this.imageSrc = img.src;
          this.loading = false;
          resolve(img);
        };
        img.onerror = () => {
          reject(new Error("Could not load image at " + imageUrl));
        };
        img.src = url;
      });
    },

    loadMapData() {
      const fetchedMapData =
        this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_DATA];
      const mapData = {
        pointSize: parseInt(fetchedMapData.pointSize || 15),
      };
      this.mapData = mapData;
    },

    async loadImage() {
      const imageUrl = this.organizationConfig[OPTIONS_SPOTS_MAP_IMAGE_URL];
      if (!imageUrl) {
        return;
      }
      await this.fetchImage(imageUrl);
      this.zoomOut();
    },

    refreshSpotsMap() {
      try {
        this.loading = true;
        this.hotspots = this.spots.map((item) => {
          return {
            x: item.map_position_x,
            y: item.map_position_y,
            spotId: item.id,
            code: item.code,
          };
        });
        const spotsMapIdSpot = {};
        for (const item of this.spots) {
          spotsMapIdSpot[item.id.toString()] = item;
        }
        this.spotsMap = spotsMapIdSpot;
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    getHotspotStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = hotspot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerWith = this.mapData.pointSize * scale;
      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale +
        offsetWidth / 2 +
        offsetX -
        markerWith / 2;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight / 2;

      return {
        position: "absolute",
        left: `${newLeft}px`,
        top: `${newTop}px`,
        width: `${markerWith}px`,
        height: `${markerHeight}px`,
      };
    },

    getMapModalStyle(hotspot) {
      const { offsetX, offsetY, scale } = this.zoomOptions;
      const { x: left, y: top } = this.currentSpot;
      if (!this.$refs.imageContainer) {
        return {
          position: "absolute",
          left: left + "px",
          top: top + "px",
          width: `${this.mapData.pointSize}px`,
          height: `${this.mapData.pointSize}px`,
        };
      }

      const markerHeight = this.mapData.pointSize * scale;

      const { offsetWidth, offsetHeight } = this.$refs.imageContainer;
      const newLeft =
        (left - offsetWidth / 2) * scale + offsetWidth / 2 + offsetX - 150;
      const newTop =
        (top - offsetHeight / 2) * scale +
        offsetHeight / 2 +
        offsetY -
        markerHeight -
        186 -
        5;

      return {
        left: `${newLeft}px`,
        top: `${newTop}px`,
      };
    },

    zoom(event) {
      event.preventDefault();
      const zoomSpeed = 0.1;
      const scaleFactor = event.deltaY > 0 ? -zoomSpeed : zoomSpeed;
      this.zoomOptions.scale = Math.min(
        Math.max(this.zoomOptions.scale + scaleFactor, 0.5),
        5
      );
    },

    zoomIn() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.min(this.zoomOptions.scale + zoomSpeed, 5);
    },

    zoomOut() {
      const zoomSpeed = 0.1;
      this.zoomOptions.scale = Math.max(
        this.zoomOptions.scale - zoomSpeed,
        0.5
      );
    },

    openHotspotForm(hotspot) {
      this.currentSpot = hotspot;
      this.showMapModal = true;
    },

    closeMapModal() {
      this.stopDraggingImage();
      this.currentSpot = { x: 0, y: 0 };
      this.showMapModal = false;
    },

    onMouseDownImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapModal) {
        if (this.$refs.mapModalContainer.contains(event.target)) {
          return;
        }
        this.closeMapModal();
        return;
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      this.dragStartsAt = Date.now();
      this.startDragImage(event);
    },

    onMouseMoveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.zoomOptions.isDragging) {
        this.dragImage(event);
        return;
      }
    },

    onMouseUpImage(event) {
      event.preventDefault();
      event.stopPropagation();

      if (this.showMapModal) {
        if (this.$refs.mapModalContainer.contains(event.target)) {
          return;
        }
      }
      if (this.$refs.zoomControlsContainer.contains(event.target)) {
        return;
      }

      if (Date.now() - this.dragStartsAt < 100) {
        this.openHotspotForm(this.currentSpot);
        this.zoomOptions.isDragging = false;
        return;
      }
      if (this.zoomOptions.hasDragged) {
        this.zoomOptions.isDragging = false;
        this.stopDraggingImage(event);
        return;
      }
    },

    onMouseLeaveImage(event) {
      event.preventDefault();
      event.stopPropagation();

      this.stopDraggingImage();
    },

    onMouseDownHotspot(event, hotspot) {
      event.preventDefault();
      event.stopPropagation();
      this.dragStartsAt = Date.now();
      this.currentSpot = hotspot;
    },

    startDragImage(event) {
      this.zoomOptions.isDragging = true;
      this.zoomOptions.startX = event.clientX - this.zoomOptions.offsetX;
      this.zoomOptions.startY = event.clientY - this.zoomOptions.offsetY;
    },

    dragImage(event) {
      this.zoomOptions.hasDragged = true;
      this.zoomOptions.offsetX = event.clientX - this.zoomOptions.startX;
      this.zoomOptions.offsetY = event.clientY - this.zoomOptions.startY;
      return;
    },

    stopDraggingImage(event) {
      this.zoomOptions.isDragging = false;
      this.zoomOptions.hasDragged = false;
      return;
    },

    onAddBooking(spotId) {
      this.$emit("onAddBooking", {
        spotId: spotId,
        startDate: this.searchCriteria.dateRange[0],
        endDate: this.searchCriteria.dateRange[1],
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container-fluid.loading {
  cursor: progress;
}

.image-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 12px;
  background-color: #aec082;

  .map-form {
    width: 300px;
    min-height: 160px;
    background-color: #fff;
    filter: drop-shadow(0px 25px 50px #00000040);
    border-radius: 9px;
    position: absolute;
    z-index: 20;
    padding: 8px;
    cursor: auto;
    &:after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -12px;
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 14px solid #fff;
    }
    &-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      position: relative;
      padding: 0 25px;
      p {
        color: #ef8152;
        position: relative;
        padding: 0;
        font-family: "Greycliff CF Bold", sans-serif;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        &:before {
          content: "";
          width: 14px;
          height: 19px;
          background: url("/img/e-pin.svg") no-repeat center center;
          background-size: 14px 19px;
          display: block;
          position: relative;
          top: 4px;
          left: 0;
          margin: 0 5px 0 0;
        }
      }
      .dates {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 5px 0 0 0;
        li {
          font-size: 14px;
          line-height: 16px;
          color: #374151;
          &.sep {
            margin: 0 5px;
          }
        }
      }
      button {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        i {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    &-content {
      width: 100%;
      margin: 5px 0 0 0;
      display: flex;
      flex-direction: column;
      .el-select {
        width: 100%;
        .el-input {
          input {
            &.el-input__inner {
              min-height: 37px;
            }
          }
        }
      }
      .location {
        width: 100%;
        margin: 7px 0 0 0;
        display: flex;
        flex-direction: column;
        span {
          font-size: 13px;
          line-height: 16px;
          font-family: "Greycliff CF Bold", sans-serif;
        }
        &-selected {
          width: 100%;
          min-height: 55px;
          margin: 5px 0 0 0;
          border-radius: 6px;
          background-color: #aec0824d;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          .located {
            width: 100%;
            display: flex;
            flex-direction: column;
            //align-items: center;
            padding: 10px;
            position: relative;
            &-item {
              width: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              &-image {
                max-width: 36px;
                height: 36px;
                border-radius: 50%;
                border: solid 1px #aec082;
                flex: 1;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
              &-text {
                max-width: calc(100% - 35px);
                display: flex;
                flex-direction: column;
                padding: 0 0 0 10px;
                flex: 1;
                span {
                  font-size: 12px;
                  line-height: 15px;
                  margin: 0 0 2px 0;
                  color: #374151;
                  font-family: "Greycliff CF Medium", sans-serif;
                  &:first-child {
                    font-family: "Greycliff CF Bold", sans-serif;
                  }
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
              &-delete {
                max-width: 35px;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-content: center;
                flex: 1;
                button {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: center;
                  background-color: #c81e1e;
                  border: none;
                  border-radius: 0 6px 6px 0;
                  img {
                    widows: 16px;
                    height: 16px;
                  }
                }
              }
              .add-booking {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 10px 0 0 0;
                background-color: #ef8152;
                min-height: 34px;
                color: #fff;
                border-radius: 8px;
                outline: none;
                border: none;
                font-size: 14px;
                line-height: 18px;
                font-family: "Greycliff CF Bold", sans-serif;
              }
            }
          }
          p {
            margin: 0;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            font-family: "Greycliff CF Medium", sans-serif;
          }
        }
      }
    }
  }
}

.image-container.dragging {
  cursor: move;
}
.image-container.draggingSpot {
  cursor: grabbing;
}
.image-container.loading {
  cursor: progress;
}

img {
  width: auto;
  height: auto;
  max-width: none;
  max-height: none;
  user-select: none;
}

.zoom-controls {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 20px #00000029;
  button {
    background-color: #fff;
    //border: 1px solid #ccc;
    border: none;
    border-radius: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px rgba(145, 145, 145, 0.3);
    &:hover {
      background-color: #eee;
    }
    &:first-of-type {
      border-radius: 6px 6px 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 6px 6px;
      border-bottom: none;
    }
    i {
      font-size: 16px;
      color: #576654;
      font-weight: 500;
    }
  }
}

.hotspot {
  position: absolute;
  background-color: #c1d0a1;
  border: solid 2px #7e8d6d;
  border-radius: 50%;
  cursor: pointer;
  //z-index: 10;
  transition: transform 0.1s;
  &:hover {
    .hotspot-code {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
  }
  .hotspot.searched {
    background-color: #ef8152;
    border: solid 2px #7e8d6d;
  }

  &-current {
    position: absolute;
    background-color: #0c8ce9;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-shadow {
    position: absolute;
    background-color: #c1d0a1;
    border: solid 3px #0c8ce9;
    border-radius: 50%;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-helper {
    position: absolute;
    background-color: #0c8ce9;
    border: solid 3px #0c8ce9;
    border-radius: 50%;
    z-index: 10;
    transition: transform 0.1s;
  }

  &-marker {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &-code {
    position: absolute;
    right: calc(100% + 2px);
    top: 50%;
    transform: translateY(-50%);
    z-index: 20;
    background: #ef8152 0% 0% no-repeat padding-box;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.4));
    border-radius: 10px;
    padding: 8px 13px;
    color: #fff;
    font-size: 13px;
    line-height: 16px;
    font-family: "Greycliff CF Bold", sans-serif;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:after {
      content: "";
      position: absolute;
      right: -18px;
      transform: translateY(-50%);
      top: 50%;
      width: 0;
      height: 0;
      border-left: 10px solid #ef8152;
      border-right: 10px solid transparent;
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
    }
  }
}

li.el-select-dropdown__item.spot-used,
li.el-select-dropdown__item.spot-used:hover {
  background-color: #aec0824d;
}
</style>
