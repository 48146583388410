
export const blank = (value) => {
    if (value === null || value === undefined || value === '')  return true;
    return (Array.isArray(value) && value.length === 0);
};
export const isEmpty = (value) => blank(value);

export const filled = (value) => !blank(value);

export const isNullOrUndefined = (value) =>  (value === null || value === undefined);

export const isEmptyArray = (arr) => (Array.isArray(arr) && arr.length === 0);

export const isObject = (obj) => obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj);

export const isArray = (arr) => arr !== null && !!arr && Array.isArray(arr);

export const removeUndefined = (arr) => filled(arr) ? arr.filter((v) => v !== undefined) : [];

export const getSpotState =  (spot)=>{
    
    if('state' in spot && 'pricing' in spot){
        const {state,pricing} = spot;
        return {
            price_per_day :  pricing?.avg ?? 0,
            total_price :  pricing?.total ?? 0,
            locked :  state.locked,
            status :  state.status,
        }
    }

    return {
        price_per_day :  spot.unit_price,
        total_price :  spot.total_price,
        locked :  spot.locked,
        status :  spot.status,
    };
}