<template>
  <div class="shopping-cart">
    <router-link
      :to="{ name: 'Pending Bookings' }"
      v-slot="{ href, navigate }"
      custom
    >
      <a :href="href" @click="navigate">
        <span class="icon">
          <span class="count">{{ count }}</span>
          <img src="/img/shopping-cart.svg" alt="shopping-cart" />
        </span>
        <span class="text">
          {{ $t("BOOKINGS.PENDING_BOOKING_LIST") }}
        </span>
      </a>
    </router-link>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";
import { mapGetters } from "vuex";

export default {
  name: "shopping-cart",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {},

  data() {
    return {
      count: 0,
    };
  },

  setup() {},
  created() {
    this.countDraftSalesOrder();
  },

  computed: {
    ...mapGetters("profile", { me: "me" }),
  },

  methods: {
    async countDraftSalesOrder() {
      try {
        let params = {
          filter: {
            status: ORDER_STATUS_DRAFT,
            updated_by: this.me.id,
          },
          page: {
            number: 1,
            size: 1,
          },
        };

        await this.$store.dispatch("salesOrders/list", params);
        this.count = await this.$store.getters["salesOrders/listTotal"];
      } catch (error) {
        this.count = 0;
      }
    },
  },

  watch: {},
};
</script>
