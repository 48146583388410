import { BOOKING_STATUS_DRAFT } from "../../../../constants/common";

export default {
  type: "bookings",
  status: BOOKING_STATUS_DRAFT,
  start_at: null,
  end_at: null,
  adults_count: 2,
  children_count: 0,
  pets_count: 0,
  pricing: null,
  equipment_length: null,
  passenger_extensions: null,
  driver_extensions: null,
  special_request: null,
  comment: null,
  confirmation_expires_at: null,
  is_blocked: false,
  equipment: null,
  beds_qty: 0,
  rooms_qty: 0,
  services_included: null,
  electricity: null,
  relationshipNames: ["organization", "spot", "customer"],
  organization: {
    type: "organizations",
    id: null,
  },
  spot: {
    type: "spots",
    id: null,
  },
  customer: {
    type: "customers",
    id: null,
  },
};
